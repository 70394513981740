<template>
  <v-container id="purchase" fluid tag="section" class="parent-element">
    <v-row class="sticky-child" v-if="this.onlyAdd == true">
      <v-col class="d-flex" cols="4" sm="4">
        <v-select
          :items="searchItems"
          v-model="searchItem"
          label="Search By"
          hide-details="true"
          solo
        ></v-select>
      </v-col>
      <v-col cols="8" sm="8">
        <div class="search">
          <i class="fa fa-search"></i>
          <v-text-field
            :label="searchLabel + searchItem"
            :value="searchValue"
            :hint="searchError"
            v-model="searchValue"
            v-on:keyup.enter="findProduct"
            solo
          ></v-text-field>
          <button class="btn btn-primary" @click="findProduct">Search</button>
        </div>
      </v-col>
    </v-row>
    <v-alert color="red lighten-2" dark v-if="imageFileSizeError">
      {{ imgMaxSize }}
    </v-alert>
    <!-- <v-alert color="red lighten-2" dark v-if="showItemError">
        {{ showErrorItem }}
      </v-alert> -->
    <v-container>
      <v-form ref="form" v-if="this.id == null || this.id == undefined">
        <v-container>
          <v-row>
            <v-col cols="6" md="6" v-for="(num, index) in purchaseNumber" :key="num">
              <v-card>
                <v-card-title>
                  {{ productDetails[index].name }}
                  <v-col class="text-right">
                    <v-icon
                      v-if="onlyView != true && editPurchase != true"
                      class="mt-6 ml-4"
                      @click="deletePurchase(index)"
                      :disabled="onlyView || editPurchase"
                      >mdi-delete</v-icon
                    >
                  </v-col>
                </v-card-title>
                <v-card-text class="ml-4">
                  <span style="width: 150px">
                    <b>{{
                      productDetails[index].product_variants.variant_quantity_per_pack +
                      "x " +
                      productDetails[index].product_variants.variant_volume_value +
                      " " +
                      productDetails[index].product_variants.variant_volume_type +
                      " " +
                      productDetails[index].product_variants.variant_type
                    }}</b>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                  <span style="width: 150px">
                    <b>UPC</b> :
                    {{
                      productDetails[index].product_variants.variant_upc_number
                    }}&nbsp;&nbsp;&nbsp;
                  </span>
                  <span style="width: 150px">
                    <b>SKU</b> :
                    {{
                      productDetails[index].product_variants.variant_sku_number
                    }}&nbsp;&nbsp;&nbsp;
                  </span>
                </v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="4" md="4">
                      <v-text-field
                        type="number"
                        min="1"
                        step="1"
                        class="purple-input"
                        label="Quantity"
                        v-model="quantity[index]"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        @keyup="calculateProductPrice(index)"
                        :disabled="onlyView"
                      />
                    </v-col>
                    <v-col cols="4" md="4">
                      <v-text-field
                        type="number"
                        value="0.00"
                        class="purple-input"
                        label="Price Per Unit"
                        v-model="net_bottle_cost[index]"
                        @keypress="isPriceKey"
                        @keyup="calculateProductPrice(index)"
                        :disabled="onlyView"
                      />
                    </v-col>
                    <v-col cols="4" md="4">
                      <v-text-field
                        class="purple-input"
                        label="Total Price"
                        v-model="net_price[index]"
                        readonly
                        disabled
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-form ref="form" v-if="this.editPurchase == true || this.onlyView == true">
        <v-container>
          <v-row>
            <v-col cols="6" md="6" v-for="(num, index) in purchaseNumber" :key="num">
              <v-card>
                <v-card-title>
                  <span v-if="getProduct(productId[index]).length > 0">{{
                    getProduct(productId[index])[0].name
                  }}</span>
                  <v-col class="text-right">
                    <v-icon
                      v-if="onlyView != true && editPurchase != true"
                      class="mt-6 ml-4"
                      @click="deletePurchase(index)"
                      :disabled="onlyView || editPurchase"
                      >mdi-delete</v-icon
                    >
                  </v-col>
                </v-card-title>
                <v-card-text class="ml-4">
                  <span style="width: 150px">
                    <b>{{
                      variantData[index].variant_quantity_per_pack +
                      "x " +
                      variantData[index].variant_volume_value +
                      " " +
                      variantData[index].variant_volume_type +
                      " " +
                      variantData[index].variant_type
                    }}</b>
                    &nbsp;&nbsp;&nbsp;
                  </span>

                  <span style="width: 150px">
                    <b>UPC</b> :
                    {{ variantData[index].variant_upc_number }}&nbsp;&nbsp;&nbsp;
                  </span>
                  <span style="width: 150px">
                    <b>SKU</b> :
                    {{ variantData[index].variant_sku_number }}&nbsp;&nbsp;&nbsp;
                  </span>
                </v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="4" md="4">
                      <v-text-field
                        type="number"
                        min="1"
                        step="1"
                        class="purple-input"
                        label="Quantity"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        v-model="quantity[index]"
                        @keyup="calculateProductPrice(index)"
                        :disabled="onlyView"
                      />
                    </v-col>
                    <v-col cols="4" md="4">
                      <v-text-field
                        type="number"
                        value="0.00"
                        class="purple-input"
                        label="Price Per Unit"
                        @keypress="isPriceKey"
                        v-model="net_bottle_cost[index]"
                        @keyup="calculateProductPrice(index)"
                        :disabled="onlyView"
                      />
                    </v-col>
                    <v-col cols="4" md="4">
                      <v-text-field
                        class="purple-input"
                        label="Total Unit Price"
                        v-model="net_price[index]"
                        readonly
                        disabled
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-dialog v-model="deletePurchaseToggle" max-width="500px">
          <v-card>
            <v-card-title>Are you sure you want to delete Purchase?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="deletePurchaseToggle = false"
                >Cancel</v-btn
              >
              <v-btn color="primary darken-1" small text @click="deletePurchaseItem"
                >Delete</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <v-card>
        <v-card-title>
          <v-col> Invoice/Vendor Details </v-col>
          <v-col>
            <v-select
              class="float-right"
              :items="stores"
              label="Select Stores"
              item-text="name"
              item-value="_id"
              v-model="storeId"
              hide-details="true"
              :rules="selectRule"
              :disabled="onlyView || editPurchase"
            ></v-select> </v-col
        ></v-card-title>
        <v-container>
          <v-row>
            <v-col cols="3" md="3">
              <v-text-field
                class="purple-input"
                label="Invoice No"
                v-model="invoice_no"
                :disabled="onlyView"
              />
            </v-col>

            <v-col cols="3" md="3">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    :readonly="editPurchase"
                    :disabled="onlyView"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" md="3">
              <v-text-field
                class="purple-input"
                label="Vendor Name"
                v-model="vendor_name"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="3" md="3">
              <v-text-field
                class="purple-input"
                label="Mode Of Payment"
                v-model="mode_of_payment"
                :disabled="onlyView"
              />
            </v-col>

            <v-col cols="3" md="3">
              <span>
                <!-- <v-file-input
                  label="Invoice Image"
                  counter
                  multiple
                  :rules="fileuploadSize"
                  truncate-length="15"
                  v-model="filesForUpload"
                  :disabled="onlyView"
                  accept="image/*"
                ></v-file-input> -->
                <v-file-input
                  label="Invoice Image"
                  counter
                  multiple
                  truncate-length="15"
                  v-model="filesForUpload"
                  :disabled="onlyView"
                  accept="image/*"
                  @change="checkFileSize()"
                ></v-file-input>
              </span>
              <ImageErrorDialog
                :error-dialog="errorDialog"
                :error-message="errorMessage"
                @close="errorDialog = false"
              ></ImageErrorDialog>
              <span v-if="editPurchase">
                <div v-for="image in files" :key="image">
                  <v-col style="padding-left: 30px">
                    <v-avatar>
                      <v-img
                        aspect-ratio="1"
                        :src="imageUrl + image"
                        :lazy-src="imageUrl + image"
                        @click="openNewTab(imageUrl + image)"
                        style="cursor: pointer"
                      />
                    </v-avatar>
                    <v-btn
                      icon
                      x-small
                      rounded
                      color="error"
                      style="background-color: #fff !important"
                      @click="deleteImage(image)"
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </v-col>
                </div>
              </span>
            </v-col>
            <v-col cols="3" md="3">
              <v-text-field
                class="purple-input"
                label="Tax"
                v-model="tax"
                :disabled="onlyView"
                @keyup="changeTaxCalulateProductPrice()"
              />
            </v-col>

            <v-col cols="3" md="3">
              <v-text-field
                class="purple-input"
                label="Total Amount"
                v-model="total_amount"
                disabled
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                class="purple-input"
                label="Notes"
                v-model="notes"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-row>
        <v-col cols="12">
          <v-btn
            type="button"
            color="primary"
            class="mr-0 float-right"
            @click="submit"
            :loading="loading"
          >
            {{ onlyView == true ? "Back" : editPurchase == true ? "Update" : "Submit" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="deletePurchaseToggle" max-width="500px">
      <v-card>
        <v-card-title>Are you sure you want to delete Purchase?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deletePurchaseToggle = false"
            >Cancel</v-btn
          >
          <v-btn color="primary darken-1" small text @click="deletePurchaseItem"
            >Delete</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imageDelete" max-width="500px">
      <v-card>
        <v-card-title>Are you sure you want to delete image?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">Delete</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ImageErrorDialog from "@/views/dashboard/components/modals/ImageErrorDialog.vue";

export default {
  props: ["id", "action"],
  components: {
    ImageErrorDialog,
  },
  data() {
    return {
      searchError: "",
      searchItems: ["UPC", "SKU", "Product Name"],
      searchLabel: "Searching By ",
      searchValue: null,
      searchItem: "UPC",
      productDetails: [],
      stores: [],
      categories: [],
      subCategories: [],
      products: [],
      files: [],
      productsVariants: [],
      variantData: [],
      editPurchase: null,
      onlyView: false,
      onlyAdd: false,
      selectRule: [(v) => !!v || "Select store"],
      inputRule: [(v) => !!v || "Field is required"],
      categoryId: [],
      subCategoryId: [],
      productId: [],
      productNames: [],
      variantId: [],
      invoice_no: [],
      date: "",
      margin: [],
      net_price: [],
      retail_price: [],
      upc: [],
      sku: [],
      unit_cost: [],
      quantity: [],
      storeId: "",
      tax: "0",
      net_bottle_cost: [],
      total_amount: "0",
      vendor_name: "",
      vendor_address: "",
      mode_of_payment: "",
      purchaseImage: "",
      loading: false,
      showItemError: false,
      showErrorItem: null,
      menu: false,
      fileuploadSize: [
        (v) => !v || v.size < 25000 || "Image Size should be less than 25 KB!",
      ],
      purchaseNumber: [],
      fileuploadSize: [
        (v) => !v || v.size < 25000 || "Image Size should be less than 25 KB!",
      ],
      arrayNumber: 1,
      deletePurchaseToggle: false,
      deletePurchaseIndex: "",
      imageUrl: process.env.VUE_APP_Image_Link + "Purchases/",
      allCategories: [],
      allSubCategories: [],
      allProducts: [],
      imgMaxSize: [],
      imageDelete: false,
      deleteImageConfirm: "",
      imageFileSizeError: false,
      filesForUpload: [],
      errorDialog: false,
      errorMessage: "",
      notes: "",
    };
  },
  methods: {
    clear() {
      this.files = [];
    },
    isPriceKey(event) {
      const reg = /^-?\d*(\.\d{0,2})?$/;
      let input = event.target.value + String.fromCharCode(event.charCode);

      if (!reg.test(input)) {
        event.preventDefault();
      }
    },
    getStoreList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "store/getall")
        .then((response) => {
          if (response.status == 200) {
            this.stores = response.data.stores;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllCategories() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "category/getall")
        .then((response) => {
          if (response.status == 200) {
            this.allCategories = response.data.categories;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCategoriesArray(storeId) {
      return this.allCategories.filter((el) => el.store_id == storeId);
    },
    getAllSubCategories() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "sub-category/getall")
        .then((response) => {
          if (response.status == 200) {
            this.allSubCategories = response.data.subCategories;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSubCategoriesArray(categoryId) {
      if (categoryId != undefined) {
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL + "product/getCategoryMargin/" + categoryId
          )
          .then((response) => {
            if (response.status == 200) {
              this.margin = response.data.categories.margin;
            }
          })
          .catch((error) => {
            console.log(error);
          });
        return this.allSubCategories.filter(
          (subCategory) => subCategory.category_id == categoryId
        );
      }
    },
    getAllProducts() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "product/getall")
        .then((response) => {
          if (response.status == 200) {
            this.allProducts = response.data.products;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProduct(productId) {
      return this.allProducts.filter((product) => product._id == productId);
    },
    getProductsArray(subCategoryId) {
      return this.allProducts.filter(
        (product) => product.sub_category_id == subCategoryId
      );
    },
    getProductsVariants(productId, index) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "product/edit/" + productId)
        .then((response) => {
          if (response.status == 200) {
            this.productsVariants = response.data.product.product_variants;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVariant(productId) {
      let variants = [];
      this.allProducts.filter((product) => {
        if (product._id == productId) {
          variants = product.product_variants;
        }
      });
      return variants;
    },
    calculateProductPrice(index) {
      console.log(this.margin);

      let catIndex = this.allCategories.findIndex(
        (el) => el._id == this.categoryId[index]
      );
      this.margin = this.allCategories[catIndex].margin;

      if (this.margin != "") {
        let marginProductPrice = (this.net_bottle_cost[index] * this.margin) / 100;
        let retailPrice = +this.net_bottle_cost[index] + +marginProductPrice;
        this.$set(this.retail_price, index, parseFloat(retailPrice).toFixed(2));
      }
      if (this.quantity[index] != null) {
        let netPrice = this.quantity[index] * this.net_bottle_cost[index];
        this.$set(this.net_price, index, parseFloat(netPrice).toFixed(2));
        let totalAmountofPurchase = 0;
        this.net_price.forEach((netPrice) => {
          totalAmountofPurchase += parseFloat(netPrice);
        });
        let addTaxinTotalPurchaseAmount = totalAmountofPurchase + parseFloat(this.tax);
        this.total_amount = parseFloat(addTaxinTotalPurchaseAmount).toFixed(2);
      }
    },
    changeTaxCalulateProductPrice() {
      let totalAmountofPurchase = 0;
      this.net_price.forEach((netPrice) => {
        totalAmountofPurchase += parseFloat(netPrice);
      });
      let taxCalulate = 0;
      if (this.tax == "") {
        taxCalulate = 0;
      } else {
        taxCalulate = this.tax;
      }
      let addTaxinTotalPurchaseAmount =
        parseFloat(totalAmountofPurchase) + parseFloat(taxCalulate);
      this.total_amount = parseFloat(addTaxinTotalPurchaseAmount).toFixed(2);
    },
    getVariantData(variantId, productId, index) {
      this.getProductsVariants(productId, index);
      if (this.variantData[index] != null) {
        setTimeout(() => {
          this.productsVariants.forEach((variant) => {
            if (variant._id == variantId) {
              // this.variantData.push(variant);
              this.$set(this.variantData, index, variant);
            }
          });
        }, 1000);
      } else {
        setTimeout(() => {
          this.productsVariants.forEach((variant) => {
            if (variant._id == variantId) {
              this.variantData.push(variant);
            }
          });
        }, 1000);
      }
    },
    // selectVariantFile() {
    // this.files.forEach((file) => {
    //   this.sendFiles.push(file);
    // });
    // },
    openNewTab(url) {
      window.open(url, "_blank");
    },
    deleteImage(image) {
      this.imageDelete = true;
      this.deleteImageConfirm = image;
    },
    closeDelete() {
      this.imageDelete = false;
      this.deleteImageConfirm = "";
    },
    deleteItemConfirm() {
      this.imageDelete = false;
      let data = {
        id: this.id,
        name: this.deleteImageConfirm,
      };
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "purchase/delete/image", data)
        .then((response) => {
          if (response.status == 200) {
            this.closeDelete();
            this.getPurchaseData(this.id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPurchaseData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "purchase/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.storeId = response.data.purchase.store_id;
            this.invoice_no = response.data.purchase.invoice_no;
            this.date = response.data.purchase.date;
            this.tax = response.data.purchase.tax;
            this.total_amount = response.data.purchase.total_amount;
            this.vendor_name = response.data.purchase.vendor_name;
            this.vendor_address = response.data.purchase.vendor_address;
            this.mode_of_payment = response.data.purchase.mode_of_payment;
            this.files = response.data.purchase.images;
            this.notes = response.data.purchase.notes;

            // let totalPurchase = [];
            let categoriesId = [];
            let subCategoriesId = [];
            let productsId = [];
            let variantsId = [];
            let quantities = [];
            let unitCost = [];
            let netBottleCost = [];
            let variantDataEdit = [];
            let retailPrice = [];
            let netPrice = [];
            let upc = [];
            let sku = [];
            response.data.purchase.purchase_data.forEach((purchase, index) => {
              // totalPurchase.push(index);
              this.addMorePurchase();
              categoriesId.push(purchase.category_id);
              subCategoriesId.push(purchase.subCategory_id);
              productsId.push(purchase.product_id);
              variantsId.push(purchase.variant_id);
              quantities.push(purchase.quantity);
              netBottleCost.push(purchase.net_bottle_cost);
              retailPrice.push(purchase.retail_price);
              netPrice.push(purchase.net_price);
              variantDataEdit.push(purchase.variant_data);
              upc.push(purchase.upc);
              sku.push(purchase.sku);
              // this.getVariantData(
              //   purchase.variant_id,
              //   purchase.product_id,
              //   index
              // );
            });
            // this.purchaseNumber = totalPurchase;
            this.categoryId = categoriesId;
            this.subCategoryId = subCategoriesId;
            this.productId = productsId;
            this.variantId = variantsId;
            this.quantity = quantities;
            this.unit_cost = unitCost;
            this.net_bottle_cost = netBottleCost;
            this.retail_price = retailPrice;
            this.net_price = netPrice;
            this.variantData = variantDataEdit;
            this.upc = upc;
            this.sku = sku;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addMorePurchase() {
      if (this.editPurchase) {
        let last = this.purchaseNumber.length + 1;
        this.purchaseNumber.push(last);
      } else {
        this.purchaseNumber.push(this.arrayNumber);
        this.arrayNumber++;
      }
    },
    deletePurchase(index) {
      this.deletePurchaseToggle = true;
      this.deletePurchaseIndex = index;
    },
    deletePurchaseItem() {
      this.$delete(this.purchaseNumber, this.deletePurchaseIndex);
      this.$delete(this.categoryId, this.deletePurchaseIndex);
      this.$delete(this.productDetails, this.deletePurchaseIndex);
      this.$delete(this.productId, this.deletePurchaseIndex);
      this.$delete(this.variantId, this.deletePurchaseIndex);
      this.$delete(this.quantity, this.deletePurchaseIndex);
      this.$delete(this.net_bottle_cost, this.deletePurchaseIndex);
      this.$delete(this.net_price, this.deletePurchaseIndex);
      this.deletePurchaseToggle = false;
    },
    submit() {
      console.log(this.filesForUpload);
      this.showError = false;
      // if (this.$refs.form.validate()) {
      this.loading = true;
      let productPurchaseDetails = [];
      let isAllRetailPriceAvailable = true;
      this.purchaseNumber.forEach((purchase, index) => {
        if (
          this.retail_price[index] != null &&
          this.retail_price[index] != undefined &&
          this.retail_price[index] != NaN &&
          this.retail_price[index] != "NaN"
        ) {
          productPurchaseDetails.push({
            category_id: this.categoryId[index],
            subCategory_id: this.subCategoryId[index],
            product_id: this.productId[index],
            variant_id: this.variantId[index],
            quantity: this.quantity[index],
            net_bottle_cost: this.net_bottle_cost[index],
            retail_price: this.retail_price[index],
            net_price: this.net_price[index],
            upc: this.upc[index],
            sku: this.sku[index],
          });
        } else {
          isAllRetailPriceAvailable = false;
        }
      });

      if (isAllRetailPriceAvailable == false) {
        this.showItemError = true;
        this.showErrorItem = "Something went wrong! Contact administrator.";
        console.error("RetailPriceNotCalculatedCorrectly");
        return;
      }

      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("store_id", this.storeId);
      formData.append("invoice_no", this.invoice_no);
      formData.append("date", this.date);
      formData.append("tax", this.tax);
      formData.append("total_amount", this.total_amount);
      formData.append("vendor_name", this.vendor_name);
      formData.append("vendor_address", this.vendor_address);
      formData.append("mode_of_payment", this.mode_of_payment);
      formData.append("purchase_data", JSON.stringify(productPurchaseDetails));
      formData.append("notes", this.notes);
      // for (let file of this.files) {
      //   console.log(formData);
      //   formData.append("images[]", file, file.name);
      // }
      for (let file of this.filesForUpload) {
        formData.append("images[]", file, file.name);
      }
      // let data = {
      //   id: this.id,
      //   store_id: this.storeId,
      //   invoice_no: this.invoice_no,
      //   date: this.date,
      //   tax: this.tax,
      //   total_amount: this.total_amount,
      //   vendor_name: this.vendor_name,
      //   vendor_address: this.vendor_address,
      //   mode_of_payment: this.mode_of_payment,
      //   purchase_data: productPurchaseDetails,
      // };
      if (this.onlyView) {
        this.$router.push({ name: "Purchases" });
      } else if (this.editPurchase == true) {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "purchase/update", formData)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.$router.push({
                name: "Purchases",
                params: { edit: true },
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.data.item != null) {
              this.showItemError = true;
              this.showErrorItem = error.response.data.item[0];
            }
          });
      } else {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "purchase/create", formData)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.$router.push({ name: "Purchases", params: { add: true } });
            }
          })
          .catch((error) => {
            this.loading = false;
            this.imageFileSizeError = true;
            if ((this.imageFileSizeError = true)) {
              window.scrollTo(0, 0);
            }
            if (error.response.data.message != null) {
              this.showItemError = true;
              // this.showErrorItem = error.response.data.item[0];
              this.imgMaxSize = error.response.data.message;
            }
          });
      }
      // }
    },
    findProduct() {
      let upcValue = "null";
      let skuValue = "null";
      let productNameValue = "null";
      this.searchValue = this.searchValue.trim();
      if (this.searchItem.toString() == "UPC") {
        upcValue = this.searchValue;
        console.log(upcValue);
      }
      if (this.searchItem.toString() == "SKU") {
        skuValue = this.searchValue;
        console.log(skuValue);
      }
      if (this.searchItem.toString() == "Product Name") {
        productNameValue = this.searchValue;
        console.log(productNameValue);
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "getProductFromUpc/" +
            upcValue +
            "/" +
            skuValue +
            "/" +
            productNameValue
        )
        .then((response) => {
          this.searchError = "";
          let product = response.data.data;
          if (product != null) {
            console.log("this.productDetails", this.productDetails);
            if (product.product_variants != null) {
              let prIndex = this.productDetails.findIndex(
                (pr) =>
                  pr.product_variants.variant_sku_number ==
                    product.product_variants.variant_sku_number ||
                  pr.product_variants.variant_upc_number ==
                    product.product_variants.variant_upc_number
              );
              console.log(prIndex);
              if (prIndex >= 0) {
                this.searchError =
                  "This " +
                  this.searchItem +
                  " Number " +
                  this.searchValue +
                  "is already added in current list.";
              } else {
                this.addMorePurchase();
                this.productDetails.push(product);
                this.categoryId.push(product.category_id);
                this.subCategoryId.push(product.sub_category_id);
                this.productId.push(product._id);
                this.variantId.push(product.product_variants._id);
                this.upc.push(product.product_variants.variant_upc_number);
                this.sku.push(product.product_variants.variant_sku_number);
              }
            } else {
              this.searchError =
                "No Product Found for the " +
                this.searchItem +
                " Number " +
                this.searchValue;
            }
          } else {
            this.searchError =
              "No Product Found for the " +
              this.searchItem +
              " Number " +
              this.searchValue;
          }

          setTimeout(() => {
            this.searchError = "";
          }, 5000);
        })
        .catch((error) => {
          this.loading = false;
          this.searchError =
            "No Product Found for the " + this.searchItem + " Number " + this.searchValue;

          setTimeout(() => {
            this.searchError = "";
          }, 5000);
        });
    },
    checkFileSize() {
      console.log("called");
      const maxSizeInBytes = 25 * 1024; // 50kb
      const files = this.filesForUpload.length > 0 ? this.filesForUpload[0] : [];

      console.log("filees : ", files);
      if (files) {
        if (files.size > maxSizeInBytes) {
          // this.errorMessage = `File '${files.name}' exceeds the maximum size limit (50kb).`;
          this.errorMessage = `Please choose an image with size less than 25 KB.`;
          this.errorDialog = true;
          this.filesForUpload = []; // Clear the selected files
          return;
        }
      }
    },
  },
  mounted() {
    const route = this.$router.currentRoute;
    console.log(route);
    this.getStoreList();
    this.getAllCategories();
    this.getAllSubCategories();
    this.getAllProducts();
    if (this.id != undefined) {
      this.getPurchaseData(this.id);
    }
    if (route.name == "Add Purchase") {
      this.onlyAdd = true;
    }
    if (route.name == "View Purchase") {
      this.onlyView = true;
    }
    if (route.name == "Edit Purchase") {
      this.editPurchase = true;
    }
  },
};
</script>
<style scoped>
.parent-element {
  position: absolute;
  overflow: visible;
  height: 100%;
}

.sticky-child {
  position: sticky;
  top: 0;
  z-index: 1000;
}
.vertical-middle {
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.search {
  position: relative;
}

.search input {
  text-indent: 25px;
}

.search input:focus {
  box-shadow: none;
}

.search .fa-search {
  position: absolute;
  top: 20px;
  left: 16px;
}

.search button {
  position: absolute;
  top: 3px;
  right: 5px;
  height: 40px;
  width: 100px;
  background: #cf9602 !important;
  border-color: #cf9602 !important;
  color: white;
  border-radius: 4px;
}
</style>
